<template>
  <b-modal ref="corporate-number-search-modal" size="lg" hide-footer hide-header-close no-close-on-backdrop>
    <template v-slot:modal-title>
      法人番号を検索
    </template>
    <div id="search-box">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <b-form inline class="p-4 pt-2 pb-2 row" @submit.prevent="handleSubmit(onSearch)">
          <b-form-select
            class="col-3 m-1"
            v-model="searchType"
            :options="[{ text: '商号又は名称', value: 'CorporateName' }, { text: '法人番号', value: 'CorporateNumber' }]"
          />
          <validation-provider class="col m-1" :name="innerSearchType === 'CorporateNumber' ? '法人番号' : '検索キーワード'" :rules="innerSearchType === 'CorporateNumber' ? 'required|min:13|houjin':'required'" v-slot="{ errors }">
            <b-form-input class="w-100" v-model="searchString" placeholder="検索キーワード" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
          <j-button buttonType="submit" class="btn btn-primary col-2 m-1">検索</j-button>
        </b-form>
      </ValidationObserver>

    </div>
    

    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="text-left">法人番号</th>
          <th class="text-left">商号又は名称</th>
          <th class="text-left">所在地</th>
          <th class="text-center">選択</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="searchResultList.length === 0">
          <tr>
            <td colspan=4>表示するデータがありません。</td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="searchResult in searchResultList"  v-bind:key="searchResult.sequenceNumber">
          <td class="text-left">{{ searchResult.corporateNumber }}</td>
          <td class="text-left"><span class="small">{{ searchResult.furigana }}</span><br/>{{ searchResult.name }}</td>
          <td class="text-left"><span class="small">〒{{ searchResult.postCode }}</span><br/>{{ searchResult.prefectureName }}{{ searchResult.cityName }}{{ searchResult.streetNumber }}</td>
          <td><j-button class="btn btn-primary btn-xs" @click="reflect(searchResult)">選択</j-button></td>
        </tr>
        </template>
      </tbody>
    </table>
    <div class="controls mt-2 text-center">
      <j-button variant="danger" @click.prevent="reflect(null)">該当なし</j-button>
    </div>
  </b-modal>
</template>

<script>
import CompanyInfoModel from '@/model/CompanyInfo';


export default {
  props: ['companyName'],
  data: function(){
    return {
      searchResultList: [],
      innerSearchType: 'CorporateName',
      innerSearchString: this.companyName
    } 
  },
  computed:{
    searchString: {
      get() {
        return this.innerSearchString
      },
      set(value) {
        this.innerSearchString = value
      }
    },
    searchType: {
      get() {
        return this.innerSearchType
      },
      set(value) {
        this.innerSearchType = value
      }
    }
  },
  methods:{
    onSearch(){
      CompanyInfoModel.getCorporateNumberSearchResult(this.$store.state.user.companyCode, this.searchType, this.searchString).then((result) => {
        if(result.data.httpStatusCode === 200) {
          this.searchResultList = result.data.responseDTOList;
          if(result.data.responseDTOList.length === 0) {
            this.$errorMessage("選択した条件に当てはまるデータが見つかりませんでした。");
          }
        } else {
          this.searchResultList = [];
          this.$errorMessage(result.data.errorMessage);
        }
      }).catch(reason => {
        this.$errorMessage(undefined, {reason});
      });
    },
    reflect(searchResult) {
      this.$refs['corporate-number-search-modal'].hide();
      this.$emit('getResult', searchResult);
      this.reset();
    },
    reset() {
      this.searchResultList = [];
      this.innerSearchType = "CorporateName";
      this.innerSearchString = "";
    },
    show(){
      this.innerSearchString = this.companyName;
      this.onSearch();
      this.$refs['corporate-number-search-modal'].show();
    }
  }
}
</script>

<style scoped>
#search-box {
  backGround-color: #F7F7F7;
}
.row{
  align-items:flex-start;
}
.w-100{
  width: 100%;
}
</style>