<template>

  <div class="mt-4 card biz-primary">
    <div class="card-header">
      {{$t('COMPANY_INFO.HEADER_BASIC_INFO')}}
      <p>{{$t('COMPANY_INFO.DESC_BASIC_INFO')}}</p>
    </div>
    <div class="card-body">
      <div class="biz-form-group seperated">
        <label class="biz-form-label required">
          {{$t('COMPANY_INFO.APPLICATION_TYPE')}}
        </label>
        <div class="biz-form-body">
          <validation-provider :name="$t('COMPANY_INFO.APPLICATION_TYPE')" rules="required" v-slot="{ errors }">
            <b-radio-group v-model="company.applicationType">
              <b-radio value="1" @change="setInvoiceRegistrationNumber">{{$t('COMPANY_INFO.CORPORATE')}}</b-radio>
              <b-radio value="2">{{$t('COMPANY_INFO.SELF_EMPLOYED')}}</b-radio>
            </b-radio-group>
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>

      <!-- VinhVN fix bug BUG23023-1959 -->
      <div class="biz-form-group seperated mt-3">
        <label class="biz-form-label required">
          {{$t('COMPANY_INFO.COMPANY_NAME')}}
        </label>
        <div class="biz-form-body">
          <div class="row">
            <div class="col-4">
              <b-form-group class="biz-input-group" :label="$t('COMPANY_INFO.COMPANY_FULL_NAME_WITH_LEGAL_ENTITY')">
                <validation-provider :name="$t('COMPANY_INFO.COMPANY_FULL_NAME')" rules="required" v-slot="{ errors }">
                  <b-form-input 
                    v-if="company.applicationType == '1'"
                    type="text" 
                    v-b-popover.focus.top="$t('COMPANY_INFO.COMPANY_FULL_NAME_DESC')" 
                    :placeholder="$t('COMPANY_INFO.COMPANY_FULL_NAME_PLACEHOLDER')" 
                    v-model="company.fullName"/>
                  <b-form-input 
                    v-else-if="company.applicationType == '2'" 
                    type="text" 
                    :placeholder="$t('COMPANY_INFO.COMPANY_FULL_NAME_PLACEHOLDER')" 
                    v-model="company.fullName"/>
                  <span class="invalid-feedback">{{errors[0]}}</span>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col biz-offset" style="margin-top: 1.5rem; margin-left: -1rem;" v-if="company.applicationType == '1'">
              <a href="javascript:void(0)" class="btn btn-light"
                v-on:click="$refs['corporate-search-modal'].show();">
                {{$t('COMPANY_INFO.AUTO_LOAD_COMPANY_INFO')}}
              </a>
              <CorporateNumberSearchModal ref="corporate-search-modal" :companyName="company.fullName" @getResult="reflectResult" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group class="biz-input-group" :label="$t('COMPANY_INFO.COMPANY_NAME_KANA_WITHOUT_LEGAL_ENTITY')">
                <validation-provider :name="$t('COMPANY_INFO.COMPANY_NAME_KANA')" :rules="{required:true, katakanaOnly: true}" v-slot="{ errors }">
                  <b-form-input 
                    v-if="company.applicationType == '1'"
                    type="text" 
                    :placeholder="$t('COMPANY_INFO.COMPANY_NAME_KANA_PLACEHOLDER')" 
                    :disabled="nameKanaDisabled"
                    v-model="company.nameKana"
                    v-b-popover.focus.top="$t('COMPANY_INFO.COMPANY_NAME_KANA_DESC')"/>
                  <b-form-input 
                    v-else-if="company.applicationType == '2'" 
                    type="text" 
                    :placeholder="$t('COMPANY_INFO.COMPANY_NAME_KANA_PLACEHOLDER')" 
                    v-model="company.nameKana"/>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <!-- VinhVN fix bug BUG23023-1959 hide 代表取締役 -->
      <!-- <div class="biz-form-group seperated mt-3" v-else-if="company.applicationType == '2'">
        <label class="biz-form-label required">{{$t('COMPANY_INFO.CEO')}}</label>
        <div class="biz-form-body">
          <div class="row">
            <div class="col-3">
              <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANJI')">
                <validation-provider :name="$t('COMMON.NAME_KANJI')" rules="required" v-slot="{ errors }">
                  <b-form-input type="text" :placeholder="$t('COMMON.NAME_KANJI_PLACEHOLDER')" v-model="company.fullName"></b-form-input>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANA')">
                <validation-provider :name="$t('COMMON.NAME_KANA')" :rules="{required:true, katakanaOnly: true}" v-slot="{ errors }">
                  <b-form-input type="text" :placeholder="$t('COMMON.NAME_KANA_PLACEHOLDER')" v-model="company.nameKana"></b-form-input>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group class="biz-input-group" :label="$t('COMMON.BIRTH_DATE')">
                <div class="row">
                  <div class="ml-3">
                    <validation-provider :name="$t('COMMON.YEAR')" v-slot="{ errors }">
                      <year-select css-class="d-inline-block" css-style="width: 100px;" v-model="company.ceoBirthYear"/>
                      <span class="unit ml-2">{{$t('COMMON.YEAR_LABEL')}}</span>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="ml-3">
                    <validation-provider :name="$t('COMMON.MONTH')" v-slot="{ errors }">
                      <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.MONTH')" v-model="company.ceoBirthMonth">
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('COMMON.MONTH_PLACEHOLDER')}}</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="i in 12" v-bind:key="i" :value="i - 1">{{i}}</b-form-select-option>
                      </b-form-select><span class="unit ml-2">{{$t('COMMON.MONTH_LABEL')}}</span>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="ml-3">
                    <validation-provider :name="$t('COMMON.DAY')" v-slot="{ errors }">
                      <b-form-select class="d-inline-block ml-2" style="width: 80px;" :aria-placeholder="$t('COMMON.DAY')" v-model="company.ceoBirthDay">
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('COMMON.DAY_PLACEHOLDER')}}</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="i in 31" v-bind:key="i" :value="i">{{i}}</b-form-select-option>
                      </b-form-select><span class="unit ml-2">{{$t('COMMON.DAY')}}</span>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </div> -->

      <div class="biz-form-group seperated mt-3" v-if="company.applicationType == '1'">
        <label class="biz-form-label required">{{$t('COMPANY_INFO.CORPORATION_NUMBER')}}</label>
        <div class="biz-form-body">
          <div class="row">
            <div class="col-3">
              <b-form-group class="biz-input-group">
                <validation-provider :name="$t('COMPANY_INFO.CORPORATION_NUMBER')" rules="required|min:13|houjin" v-slot="{ errors }">
                  <b-form-input type="text" 
                    :disabled="corporationNumberDisabled"
                    :placeholder="$t('COMPANY_INFO.CORPORATION_NUMBER_PLACEHOLDER')" v-model="company.corporationNumber"/>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <div class="biz-form-group seperated mt-3">
          <label class="biz-form-label required">
            {{$t('COMPANY_INFO.QUALIFIED_INVOICE_APPLICANT')}}
          </label>
          <div class="biz-form-body">
          <div class="row">
            <div class="col-4">
              <validation-provider :name="$t('COMPANY_INFO.QUALIFIED_INVOICE_APPLICANT')" rules="required" v-slot="{ errors }">
                <b-radio-group v-model="company.invoiceRegistrationType">
                  <b-radio value="1">{{$t('COMPANY_INFO.NOT_REGISTERED')}}</b-radio>
                  <b-radio value="2" @change="setInvoiceRegistrationNumber">{{$t('COMPANY_INFO.REGISTERED')}}</b-radio>
                </b-radio-group>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <div class="biz-form-group seperated mt-3" v-if="company.invoiceRegistrationType == '2'">
        <label class="biz-form-label">{{$t('COMPANY_INFO.QUALIFIED_INVOICE_REGISTRATION_NUMBER')}}</label>
        <div class="biz-form-body">
          <div class="row">
            <div class="col-5">
              <b-form-group class="biz-input-group">
                <validation-provider :name="$t('COMPANY_INFO.QUALIFIED_INVOICE_REGISTRATION_NUMBER')" rules="min:13" v-slot="{ errors }">
                  <b-input-group prepend="T">
                    <b-form-input type="text" :placeholder="$t('COMPANY_INFO.REGISTER_NUMBER_PLACEHOLDER')" v-model="company.invoiceRegistrationNumber"
                    :disabled="company.applicationType == '1'">
                    </b-form-input>
                  </b-input-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <div class="biz-form-group mt-3">
        <label class="biz-form-label required">
          {{ company.applicationType === '2' ?
              $t('COMPANY_INFO.REGISTERED_ADDRESS'):
              $t('COMPANY_INFO.COMPANY_ADDRESS')}}
        </label>
        <div class="biz-form-body">
          <div class="row">
            <div class="col-5">
              <b-form-group class="biz-input-group" :label="$t('COMMON.POSTAL_CODE')">
                <validation-provider vid="companyPostalCode" :name="$t('COMMON.POSTAL_CODE')" rules="required|numericPostalNotHypen" v-slot="{ errors }">
                  <b-form-input 
                    type="text"
                    class="d-inline-block" 
                    style="width: 180px;" 
                    v-b-popover.focus.top="$t('COMMON.POSTAL_CODE_DESC')" 
                    :placeholder="$t('COMMON.POSTAL_CODE_PLACEHOLDER')"
                    v-model="company.postalCode"></b-form-input>
                  <j-button class="ml-2" variant="light" buttonType="button" @click="onAutoAddress()">{{$t('COMMON.AUTO_LOAD_ADDRESS')}}</j-button>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group class="biz-input-group" :label="$t('COMMON.ADDRESS_1')">
                <validation-provider vid="companyAddress1" :name="$t('COMMON.ADDRESS_1')" rules="required" v-slot="{ errors }">
                  <b-form-input type="text" v-model="company.address1" :placeholder="$t('COMMON.ADDRESS_1_PLACEHOLDER')"></b-form-input>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-4">
              <div class="biz-input-group">
                <label>
                  {{$t('COMMON.ADDRESS_2')}}
                </label>
                <validation-provider vid="companyAddress2" :name="$t('COMMON.ADDRESS_2')" rules="required" v-slot="{ errors }">
                  <b-form-input
                    type="text" 
                    v-model="company.address2" 
                    v-b-popover.focus.top="$t('COMMON.ADDRESS_2_DESC')" 
                    :placeholder="$t('COMMON.ADDRESS_2_PLACEHOLDER')"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>


<script>
  import AreaModel from '@/model/Area';
  import CorporateNumberSearchModal from '@/components/company/CorporateNumberSearchModal.vue';
  // import YearSelect from '@/components/common/YearSelect.vue';
  export default {
    props: ["value"],
    components: { CorporateNumberSearchModal },
    data(){
      return {
        nameKanaDisabled: true,
        corporationNumberDisabled: true,
        company: this.value
      }
    },
    watch: {
      value: function(val){
        this.company = val;
        this.setInvoiceRegistrationNumber()
      },
      company: function(val){
        this.$emit('input', val);
      }
    },
    methods: {
      onAutoAddress(){
        let postalCode = this.company.postalCode.replaceAll(/-/g, '');
        AreaModel.getAddress(postalCode).then(result => {
          this.$set(this.company, "addressAreaCode", result.data.areaCode);
          this.$set(this.company, "address1", result.data.address);
        }).catch(reason => console.error(reason));
      },
      reflectResult(value){
        if(value == null){
          this.nameKanaDisabled = false;
          this.corporationNumberDisabled = false;
        }else{
          this.company.corporationNumber = value.corporateNumber;
          this.corporationNumberDisabled = !!value.corporateNumber;
          this.company.fullName = value.name;
          this.company.nameKana = value.furigana;
          this.nameKanaDisabled = !!value.furigana;
          this.company.postalCode = value.postCode;
          this.setInvoiceRegistrationNumber();

          // 住所1と住所2は一番最初の数字で分割する
          // 数字がない住所の場合は住所1に書き込む
          let address = value.prefectureName +  value.cityName + value.streetNumber;
          if(address) {
            const regex = /^([^0-9０-９]+)(.+)/;
            if(address.match(regex) != null) {
              this.company.address1 = address.replace(regex, '$1');
              this.company.address2 = address.replace(regex, '$2');
            } else {
              this.company.address1 = address;
            }
          }
        }
      },
      setInvoiceRegistrationNumber(){
        if(this.company.invoiceRegistrationType == '2' && this.company.applicationType == '1'){
          this.$set(this.company, "invoiceRegistrationNumber", this.company.corporationNumber);
        }
      }
    }
  }
</script>

<style>

</style>