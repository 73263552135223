var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 card biz-primary"},[_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.$t('COMPANY_INFO.HEADER_BASIC_INFO'))+" "),_c('p',[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.DESC_BASIC_INFO')))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"biz-form-group seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(" "+_vm._s(_vm.$t('COMPANY_INFO.APPLICATION_TYPE'))+" ")]),_c('div',{staticClass:"biz-form-body"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_INFO.APPLICATION_TYPE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-radio-group',{model:{value:(_vm.company.applicationType),callback:function ($$v) {_vm.$set(_vm.company, "applicationType", $$v)},expression:"company.applicationType"}},[_c('b-radio',{attrs:{"value":"1"},on:{"change":_vm.setInvoiceRegistrationNumber}},[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.CORPORATE')))]),_c('b-radio',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.SELF_EMPLOYED')))])],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"biz-form-group seperated mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(" "+_vm._s(_vm.$t('COMPANY_INFO.COMPANY_NAME'))+" ")]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMPANY_INFO.COMPANY_FULL_NAME_WITH_LEGAL_ENTITY')}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_INFO.COMPANY_FULL_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.company.applicationType == '1')?_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMPANY_INFO.COMPANY_FULL_NAME_DESC')),expression:"$t('COMPANY_INFO.COMPANY_FULL_NAME_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMPANY_INFO.COMPANY_FULL_NAME_PLACEHOLDER')},model:{value:(_vm.company.fullName),callback:function ($$v) {_vm.$set(_vm.company, "fullName", $$v)},expression:"company.fullName"}}):(_vm.company.applicationType == '2')?_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMPANY_INFO.COMPANY_FULL_NAME_PLACEHOLDER')},model:{value:(_vm.company.fullName),callback:function ($$v) {_vm.$set(_vm.company, "fullName", $$v)},expression:"company.fullName"}}):_vm._e(),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.company.applicationType == '1')?_c('div',{staticClass:"col biz-offset",staticStyle:{"margin-top":"1.5rem","margin-left":"-1rem"}},[_c('a',{staticClass:"btn btn-light",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$refs['corporate-search-modal'].show();}}},[_vm._v(" "+_vm._s(_vm.$t('COMPANY_INFO.AUTO_LOAD_COMPANY_INFO'))+" ")]),_c('CorporateNumberSearchModal',{ref:"corporate-search-modal",attrs:{"companyName":_vm.company.fullName},on:{"getResult":_vm.reflectResult}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMPANY_INFO.COMPANY_NAME_KANA_WITHOUT_LEGAL_ENTITY')}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_INFO.COMPANY_NAME_KANA'),"rules":{required:true, katakanaOnly: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.company.applicationType == '1')?_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMPANY_INFO.COMPANY_NAME_KANA_DESC')),expression:"$t('COMPANY_INFO.COMPANY_NAME_KANA_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMPANY_INFO.COMPANY_NAME_KANA_PLACEHOLDER'),"disabled":_vm.nameKanaDisabled},model:{value:(_vm.company.nameKana),callback:function ($$v) {_vm.$set(_vm.company, "nameKana", $$v)},expression:"company.nameKana"}}):(_vm.company.applicationType == '2')?_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMPANY_INFO.COMPANY_NAME_KANA_PLACEHOLDER')},model:{value:(_vm.company.nameKana),callback:function ($$v) {_vm.$set(_vm.company, "nameKana", $$v)},expression:"company.nameKana"}}):_vm._e(),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])])]),(_vm.company.applicationType == '1')?_c('div',{staticClass:"biz-form-group seperated mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.CORPORATION_NUMBER')))]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_INFO.CORPORATION_NUMBER'),"rules":"required|min:13|houjin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","disabled":_vm.corporationNumberDisabled,"placeholder":_vm.$t('COMPANY_INFO.CORPORATION_NUMBER_PLACEHOLDER')},model:{value:(_vm.company.corporationNumber),callback:function ($$v) {_vm.$set(_vm.company, "corporationNumber", $$v)},expression:"company.corporationNumber"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1221149056)})],1)],1)])])]):_vm._e(),_c('div',{staticClass:"biz-form-group seperated mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(" "+_vm._s(_vm.$t('COMPANY_INFO.QUALIFIED_INVOICE_APPLICANT'))+" ")]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_INFO.QUALIFIED_INVOICE_APPLICANT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-radio-group',{model:{value:(_vm.company.invoiceRegistrationType),callback:function ($$v) {_vm.$set(_vm.company, "invoiceRegistrationType", $$v)},expression:"company.invoiceRegistrationType"}},[_c('b-radio',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.NOT_REGISTERED')))]),_c('b-radio',{attrs:{"value":"2"},on:{"change":_vm.setInvoiceRegistrationNumber}},[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.REGISTERED')))])],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),(_vm.company.invoiceRegistrationType == '2')?_c('div',{staticClass:"biz-form-group seperated mt-3"},[_c('label',{staticClass:"biz-form-label"},[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.QUALIFIED_INVOICE_REGISTRATION_NUMBER')))]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('b-form-group',{staticClass:"biz-input-group"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_INFO.QUALIFIED_INVOICE_REGISTRATION_NUMBER'),"rules":"min:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"T"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMPANY_INFO.REGISTER_NUMBER_PLACEHOLDER'),"disabled":_vm.company.applicationType == '1'},model:{value:(_vm.company.invoiceRegistrationNumber),callback:function ($$v) {_vm.$set(_vm.company, "invoiceRegistrationNumber", $$v)},expression:"company.invoiceRegistrationNumber"}})],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,889182735)})],1)],1)])])]):_vm._e(),_c('div',{staticClass:"biz-form-group mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(" "+_vm._s(_vm.company.applicationType === '2' ? _vm.$t('COMPANY_INFO.REGISTERED_ADDRESS'): _vm.$t('COMPANY_INFO.COMPANY_ADDRESS'))+" ")]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.POSTAL_CODE')}},[_c('validation-provider',{attrs:{"vid":"companyPostalCode","name":_vm.$t('COMMON.POSTAL_CODE'),"rules":"required|numericPostalNotHypen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.POSTAL_CODE_DESC')),expression:"$t('COMMON.POSTAL_CODE_DESC')",modifiers:{"focus":true,"top":true}}],staticClass:"d-inline-block",staticStyle:{"width":"180px"},attrs:{"type":"text","placeholder":_vm.$t('COMMON.POSTAL_CODE_PLACEHOLDER')},model:{value:(_vm.company.postalCode),callback:function ($$v) {_vm.$set(_vm.company, "postalCode", $$v)},expression:"company.postalCode"}}),_c('j-button',{staticClass:"ml-2",attrs:{"variant":"light","buttonType":"button"},on:{"click":function($event){return _vm.onAutoAddress()}}},[_vm._v(_vm._s(_vm.$t('COMMON.AUTO_LOAD_ADDRESS')))]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.ADDRESS_1')}},[_c('validation-provider',{attrs:{"vid":"companyAddress1","name":_vm.$t('COMMON.ADDRESS_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.ADDRESS_1_PLACEHOLDER')},model:{value:(_vm.company.address1),callback:function ($$v) {_vm.$set(_vm.company, "address1", $$v)},expression:"company.address1"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"biz-input-group"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('COMMON.ADDRESS_2'))+" ")]),_c('validation-provider',{attrs:{"vid":"companyAddress2","name":_vm.$t('COMMON.ADDRESS_2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.ADDRESS_2_DESC')),expression:"$t('COMMON.ADDRESS_2_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.ADDRESS_2_PLACEHOLDER')},model:{value:(_vm.company.address2),callback:function ($$v) {_vm.$set(_vm.company, "address2", $$v)},expression:"company.address2"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }